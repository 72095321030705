<template>
	<router-view/>
  </template>
  
  <script>
	  export default{
		  created(){
			  const route = (location.hash || '').split('#')[1] || '';
			  const path = route.split('?')[0] || '/';
			  if(['/auth'].includes(path) || this.$store.state.token )return;
			  const query = route.match(/\?/) ? this.$base.params.formatUrlSearch(route) : {};
			  delete query.__redirect;
			  this.$router.replace({path: '/auth', query:{ __redirect: !['','/','/auth'].includes(route) ? encodeURIComponent(this.$base.params.formatObjToUrlSearch( query ,path)) : ''  }})
				
		  }
	  }
	  
  </script>
  