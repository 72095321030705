import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api/index'
import {base} from '@/utils/tool.js'
Vue.use(Vuex)
export default new Vuex.Store({ 
	state:{
		userInfo: JSON.parse(localStorage.userInfo || 'null'),
		token: localStorage.token,
	},
	mutations: {
		SET(state, {k,v}){
			state[k] = v;
		},
		SET_AND_STORAGE(state, { sk, k,v, encode = 1}){
			state[k] = v;
			window.localStorage.setItem(sk || k, encode ? JSON.stringify(v) : v);
		},
	},
	actions: {
		async getInfo({commit}){
			const res = await api.getInfoUser();
			if(res.code !== 200) return res;
			commit('SET_AND_STORAGE', {k: 'token', v:res.data.token, encode:0 })
			commit('SET_AND_STORAGE', {k: 'userInfo',v:res.data})
			return res;
			
		}
	}
})