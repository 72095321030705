
<template>
	<ul class="h-tabs">
		<li v-for="item in list" :class="{'h-tabs-item':1, 'h-tabs-cur': value === item.value }" :key="item.value" @click="change(item)"><span>{{item.label}}</span></li>
	</ul>
	
</template>

<script>
	
	export default{
		name: 'h-tabs',
		props: {
			list:{
				type: Array,
				default:()=>[]
			},
			value: [String,Number],
		},
		data(){
			return{
				
			}
		},
		mounted(){
			
		},
		methods:{
			change(item){
				if(item.value === this.value) return;
				this.$emit('input',item.value)
				this.$emit('change',{v:item.value, vm:this, item})
			}
		}
		
		
	}
	
</script>
