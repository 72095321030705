import {base} from '@/utils/tool.js'
import conf from '@/utils/conf.js'
import store from '@/store/index.js'
import api from '@/api/index'

const auth = {
	getCode(){
		location.href = base.params.formatObjToUrlSearch( { appid:conf.appId, redirect_uri: conf.url ,response_type: 'code',  scope: 'snsapi_base,snsapi_userinfo', state: '',   } ,'https://open.weixin.qq.com/connect/oauth2/authorize') + '#wechat_redirect';
	},
	async verifLogin(){
		const {code} = base.params.formatUrlSearch(location.href);
		if(!code) return 'noCode';
		const res = await api.login({code});
		
		if(res.code !== 200) return 'loginFail';
		store.commit('SET_AND_STORAGE', {k: 'token', v:res.data.token, encode:0 })
		store.commit('SET_AND_STORAGE', {k: 'userInfo',v:res.data})
		return 'ok';
	},
	async authJdkConf(jsApiList = [],url = location.href){
		let res = await api.getJdkConf({ url });
		if(res.code !== 200 ) return;
		// 对&符号过敏，会签名错误
		wx.config({ ...res.data, debug: 0, jsApiList  });
		res = await new Promise(r=>(wx.ready(()=> r(1)),  wx.error(e=> r(0,console.log(e))) ))
		return res;
	},
	
	
	
	
}
export default auth;