import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/tool';

const comps = require.context('@/components', true, /\.vue$/);
comps.keys().forEach(k=>{
	let comp;
	try{ comp = comps(k) }
	catch(e){};
	comp && comp.default && comp.default.name && Vue.component(comp.default.name, comp.default);
})


import '@/assets/css/global.scss';
import elementUI from 'element-ui';
Vue.use(elementUI);
import 'element-ui/lib/theme-chalk/index.css';

import conf from '@/utils/conf.js'
import api from '@/api/index.js'
import auth from '@/utils/auth.js'
Vue.prototype.$conf = conf;
Vue.prototype.$api = api;
Vue.prototype.$auth = auth;
Vue.prototype.$Vue = Vue;
Vue.prototype.$base.rem();
window.addEventListener('resize',Vue.prototype.$base.rem)



window.rvm = new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')



