import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index.js'
Vue.use(Router);
 
['go','replace','push'].forEach(k=> {
	const func = Router.prototype[k];
	Router.prototype[k] = function(){ 
		const r = func.call(this,...arguments);
		return r && r.catch(e=> e)
	}
} )



const router = new Router({
	base: './',
	routes: [
		{ path: "/", redirect: '/auth' },
		{ path: "/auth", meta: { title: "授权"  }, component: () => import( "@/views/auth") },
		{ path: "/coupon", meta: { title: "我的优惠券"  }, component: () => import( "@/views/coupon") },
		{ path: "/receive", meta: { title: "领取优惠券"  }, component: () => import( "@/views/receive") },
		{ path: "/store", meta: { title: "商家"  }, component: () => import( "@/views/store") },
		{ path: "/reject", meta: { title: "拒绝"  }, component: () => import( "@/views/reject") },
	]
})


router.beforeEach(async (to, from, next) => {
	document.title = to.meta.title || '';
	
	next();
	
	
})
export default router;