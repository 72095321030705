

<style lang="scss" scoped>
	.h-msg{
		width: 100%;
		height: 100%;
		left: -200vw;
		top: 0;
		position: fixed;
		z-index: 2000;
		@include n-col1;
		align-items: center;
		padding: 0 0.5rem;
		padding-top: 25vh;
		gap:0.2rem;
		>li{
			border-radius: 0.05rem;
			color: #fff;
			font-size: 0.3rem;
			min-height: 0.5rem;
			padding: 0.2rem;
			background: rgba(0,0,0,0.8);
			opacity: 0;
			transform: translate(0,100%);
			@include n-col2;
			align-items: center;
			>i{
				font-size: 1rem;
			}
			>span{
				line-height: 1.6;
			}
		}
		.h-msg-show{
			transition: all 0.3s;
			opacity: 1;
			transform: translate(0,0);
		}
		&.h-msgShow{
			left: 0;
		}
	}
	
	
</style>

<template>
	<ul :class="{'h-msg':1, 'h-msgShow':list.length}" >
		<li :class="{'h-msg-show': item.state === 2}" v-for="item in list" :key="item.id"><i v-if="item.type === 'success'" class="n-icon-check-o"></i><span>{{item.text}}</span></li>
	</ul>
</template>

<script>
	 
	export default{
		name: 'h-msg',
		data(){
			return{
				list:[],
			}
		},
		mounted(){
			this.loop();
		},
		methods:{
			loop(){
				if(this._isDestroyed) return
				window.requestAnimationFrame(this.loop);
				const {list} = this;
				const t = new Date().getTime()
				for(let i = 0; i < list.length; i++){
					const v = list[i];
					v.step++;
					if(v.step > 10) v.state = 2;
					if(t < v.exp) continue;
					list.splice(i,1)
					i--;
				}
			},
			
			msg(text = '操作成功',type = 1,exp = 2000){
				this.list.push({text,type: ['none','success'][type], state:1, step:0, exp: new Date().getTime() + exp,  id:this.$base.uuid()})
			}
		}
		
		
	}
	
	
</script>
