import {base} from '@/utils/tool'

export default  ({keys, keyMap }) => {
	
	const opts = {
		async popShow({open = true, el, dir = 'vert', anim = true, timerKey = 'popTimer', stowHeight = '0px' }){
			base.isType(this[timerKey]) === 'function' ? this[timerKey]() : clearTimeout(this[timerKey]);
			const keyMap = {
				'hori': { size:'offsetWidth', maxSize: 'maxWidth' },
				'vert': { size:'offsetHeight', maxSize: 'maxHeight' },
			}
			const k = keyMap[dir];
			if(!el || !k) return;
			
			const uiRenderEnd = async ()=>{
				await new Promise(r=>  window.requestAnimationFrame(r) ) ;
				await new Promise(r=>  window.requestAnimationFrame(r) ) ;
				return;
			}
			el.style.transition = anim ? '' : 'none';
			
			if(open){
				const size = el[k.size];
				el.style[k.maxSize] = '';
				const openSize = el[k.size];
				el.style[k.maxSize] = size + 'px';
				await uiRenderEnd();
				el.style[k.maxSize] = openSize === size ? '' : openSize + 'px';
				
				if(openSize === size || !anim) return el.style[k.maxSize] = '';
				
				this[timerKey] = ()=>{
					el.style[k.maxSize] = '';
					el.removeEventListener('transitionend',this[timerKey])
					this[timerKey] = null;
				}
				el.addEventListener('transitionend',this[timerKey]);
				return;
			}
			
			el.style[k.maxSize] = el[k.size] + 'px';
			await uiRenderEnd();
			el.style[k.maxSize] = stowHeight;
		},
		
		
		mergeConf(conf, stand, opt = {}){
			base.params.merge( conf, stand, opt );
	
			const arr = [conf];
			const {isType} = base;
			
			const diffMap = new Map();
			
			while(arr.length){
				const item = arr.shift();
				if(diffMap.get(item)) continue;
				diffMap.set(item,1);
				
				const type = isType(item);
				if(!['object','array'].includes(type) || item._isVue) continue;
				
				if(!item.__ob__) this.$Vue.observable(item);
				
				for(const k in item){
					const v = item[k];
					if(['object','array'].includes(isType(v))) arr.unshift(v); 
					if(['object'].includes(type)) this.$Vue.util.defineReactive(item,k, v);
				}
			}
			
			this._watchers && this._watchers.forEach(w=> w.update());
			return conf;
		},
		getParentByName(name, vm = this){
			while(vm){
				const compName = vm.$options && vm.$options.name;
				if(compName){
					const hump = base.params.splitToHump(name,'-');
					if([name,hump,  hump[0].toUpperCase() + hump.slice(1)  ].includes(compName)) return vm;
				}
				vm = vm.$parent;
			}
			return false;
		},
		isElPopper(tg){
			const body = document.querySelector('body')
			while(tg){
				if((tg.className + '').match(/^el-/) && tg.parentElement === body) return true;
				tg = tg.parentElement;
			}
			return false;
		},
		
	}
	
	
	
	if(keys) return keys.map(k=> opts[k]).filter(v=> v);
	if(keyMap) return (Object.keys(keyMap).forEach(k=> keyMap[k] = opts[k]),keyMap );
}