import {req} from '@/utils/tool'


export default {
	login: data => req({ url: `/user/login`,  method: 'post',  data  }),
	getJdkConf: data => req({ url: `/user/getJdkConf`,  method: 'post',  data  }),
	getUser: data => req({ url: `/user/get`,  method: 'post',  data  }),
	getInfoUser: data => req({ url: `/user/getInfo`,  method: 'post',  data  }),

	bindPhoneUser: data => req({ url: `/user/bindPhone`,  method: 'post',  data  }),
	
	
	getCoupon: data => req({ url: `/coupon/get`,  method: 'post',  data  }),
	createCoupon: data => req({ url: `/coupon/create`,  method: 'post',  data  }),
	receiveCoupon: data => req({ url: `/coupon/receive`,  method: 'post',  data  }),
	drawCoupon: data => req({ url: `/coupon/draw`,  method: 'post',  data  }),
	drawListCoupon: data => req({ url: `/coupon/drawList`,  method: 'post',  data  }),
	getByUserCoupon: data => req({ url: `/coupon/getByUser`,  method: 'post',  data  }),

	dashCoupon: data => req({ url: `/coupon/dash`,  method: 'post',  data  }),
	


	// login: async()=>({code:200, data:{token:123,name:111,role:'store',username:222}}),
	// getInfoUser: async()=>({code:200, data:{token:123,name:111,role:'store',username:222}}),
	// getByUserCoupon: async()=>({code:200, data:[{name:111, id:2}] }),


}
